
<template>
  <div class="container">
    <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>新闻中心</BaseBreadItem>
    </BaseBread>
    <div class="product-list">
      <!-- 左：侧边栏组件 -->
      <BaseLeftMenu :menuList="menuList"/>
       <!-- 右：内容 -->
       <div class="article">
        <!-- 三级路由的出口 -->
        <div v-if="!id">
          <div class="order-list">
            <div v-if="loading" class="loading"></div>
            <div class="none" v-if="!loading && newsList.length === 0">
            
              <div class="cart-none">
                <img src="@/assets/images/none.png" alt="" />
               <p>暂无数据</p>
   
              </div>

            </div>
            <NewsList :news="newsList"/>
          </div>
           <!-- 分页组件 -->
          <BasePagination
            v-if="total>0"
            :current-page="reqParams.pageIndex"
            :page-size="reqParams.pageSize"
            :total="total"
            @current-change="changePagerFn"
          />
        </div>
        <div v-else>
          <RouterView />
        </div>
     
    </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { ref, reactive, watch } from "vue";
import NewsList from './components/news-list'
import { findNewsCategory,findNewsByCategoryId } from '@/api/product'

export default {
  name: 'NewsName',
  props: {
    news: {
      type: Object,
      default: () => ({})
    }
  },
  components:{
    NewsList
  },
  setup (props) {
   
    
// 获取当前路由对象
const route = useRoute();
// 获取动态路由参数的name
const id =ref()

const loading = ref(false)
const total = ref(0)
const newsList = ref([]);
// 筛选条件
const reqParams = reactive({
      pageIndex: 1,
      pageSize: 10,
    })

const menuList = ref([]);
findNewsCategory().then(data => {
  menuList.value = data.data.list
})


watch(() => route.params.id, (newVal) => {
  reqParams.categoryId = route.params.id
  findNewsByCategoryId(reqParams).then(data => {
  loading.value = true
  newsList.value = data.data.records
  total.value = data.data.total
  loading.value = false
})
}, { immediate: true })

 // 实现分页切换
const changePagerFn = (newPage) => {
  reqParams.pageIndex = newPage
}
    return { menuList,newsList,id,total,reqParams,loading,changePagerFn}
  }
}

</script>

<style scoped lang="less">
.product-list {
  display: flex;

  .article {
    width: 1000px;
  }
}

.cart-none {
  text-align: center;
  padding: 150px 0;
  background: #fff;
  img {
    width: 180px;
  }
  p {
    color: #999999;
    padding: 20px 0;
  }
}
</style>
